<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-lx-cascades"></i> 机构账号管理
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <div class="container">
      <div class="handle-box">
        <el-button type="primary" icon="el-icon-s-order" @click="handlAdd">添加</el-button>
      </div>
      <el-table
          v-loading="loading"
          :data="tableData"
          border
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
      >
        <el-table-column prop="AccountId" label="机构账号ID" width="180" show-overflow-tooltip></el-table-column>
        <el-table-column prop="Name" label="机构名称" ></el-table-column>
        <el-table-column prop="idType" label="证件类型">
          <template #default="scope">
            <div v-if=" scope.row.IdType=='CRED_ORG_USCC'">统一社会信用代码</div>
            <div v-else>工商注册号</div>
          </template>
        </el-table-column>
        <el-table-column prop="IdNumber" label="企业证件号" ></el-table-column>
        <el-table-column prop="Creator" label="负责人ID" show-overflow-tooltip></el-table-column>
        <el-table-column prop="UserName" label="负责人姓名" ></el-table-column>
        <el-table-column prop="UserMobile" label="负责人手机号码" ></el-table-column>
      </el-table>
    </div>
    <!-- 修改个人信息-->
    <el-dialog title="操作" v-model="view.edit" width="670px">
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="姓名" :required="true">
          <el-input v-model="form.UserName"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" :required="true">
          <el-input v-model="form.Mobile"></el-input>
        </el-form-item>
        <el-form-item label="机构名称" :required="true">
          <el-input v-model="form.MechName"></el-input>
        </el-form-item>
        <el-form-item label="证件类型" :required="true">
          <el-select v-model="form.idType" placeholder="请选择">
            <el-option label="统一社会信用代码" value="CRED_ORG_USCC"></el-option>
            <el-option label="工商注册号" value="CRED_ORG_REGCODE"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业证件号" :required="true">
          <el-input v-model="form.idNumber"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
                <span class="dialog-footer">
                    <el-button @click="view.edit = false">取 消</el-button>
                    <el-button type="primary" @click="saveEdit">确定</el-button>
                </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {MechfetchData,addData} from "../../api/Sign";

export default {
  data() {
    return {
      query: {
        disabled: false,
        pageTotal: 0,
      },
      loading: true,
      tableData: [],
      view: {
        edit: false
      },
      form: {}
    };
  },
  created() {
    this.getData();
  },
  methods: {
    handlAdd() {
      this.view.edit = true
      this.form = {}
    },
    saveEdit() {
      let form = this.form
      if (form.UserName && form.Mobile && form.MechName && form.idType && form.idType && form.idNumber) {
        addData(form).then(res=>{
          if (res.code==0){
            this.$message.success(res.message);
            this.getData()
            this.view.edit = false
          }else {
            this.$message.error(res.message);
          }
        })
      } else {
        this.$message.error('缺少必填项');
      }
    },
    getData() {
      MechfetchData().then(res => {
        let bindata = res.sum;
        this.tableData = bindata;
        this.loading = false
      });

    },
    // 分页导航
    handlePageChange(val) {
      this.loading = true
      this.query.pageIndex = val
      this.getData();
    }
  }
};
</script>

<style scoped>
.handle-box {
  margin-bottom: 20px;
}


.table {
  width: 100%;
  font-size: 14px;
}


</style>
